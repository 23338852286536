<template>
  <div class="occupation-container">
    <div class="occupation-item">
        <div class="occupation-header">
            <span class="logement-ref">LT0078 - Appartement de luxe</span>
            <span class="locataire-name">
                Kevin Assomo
            </span>
        </div>
        <div class="occupation-item-main">
            <div class="impaye">
                <span class="libelle">Impayés</span>
                <span class="value">185.000 fcfa</span>
            </div>
            <div class="occupation-btns">
                <button>Recharger le compte</button>
                <button>Détails</button>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .occupation-container{
        margin-top: 30px;
        display: flex;
        height: auto;
        width: 100%;
    }
    .occupation-container .occupation-item{
        padding: 10px 15px;
        width: 300px;
        height: 270px;
        border-radius: 8px;
        background: white;
    }
    .occupation-item .occupation-header{
        display: flex;
        flex-direction: column;
        height: 60px;
        width: 100%;
        border-bottom: 1px solid #eee;
    }
    .occupation-header .logement-ref{
        color: rgb(145, 145, 145);
        font-size: .9rem;
    }
    .occupation-header .locataire-name{
        margin-top: 5px;
        color: #191c22;
        font-size: 1rem;
        font-weight: 600;
    }
    .occupation-item-main{
        display: flex;
        flex-direction: column;
        height: 60%;
        width: 100%;
    }
    .occupation-item-main .impaye{
        padding: 10px 10px;
        margin-top: 20px;
        width: 100%;
        height: auto;
        background: rgb(245, 245, 245);
        border-radius: 8px;
    }
    .impaye .libelle{
        margin-right: 15px;
        font-size: .95rem;
    }
    .impaye .value{
        font-size: .95rem;
        font-weight: 600;
    }
    .occupation-item-main .occupation-btns{
        margin-top: 20px;
        display: flex;
        width: 100%;
    }
    .occupation-btns button{
        height: 45px;
        
    }
    .occupation-btns button:nth-child(1){
        border-radius: 8px 0 0 8px;
        width: 65%;
    }
    .occupation-btns button:nth-child(2){
        border-radius: 0 8px 8px 0;
        width: 35%;
    }
</style>