<template>
  <div class="container-fluid position-relative">
    <div class="fixed-btns" style="display:none">
      <button><span>Créer bail</span></button>
      <button @click="mobileMenuVisible = true">
        <span>Plus d'options</span> <i class="ik ik-grid"></i>
      </button>
    </div>
    <!--page header start -->
    <div class="row">
      <div class="navba">
        <div class="navbar-left">
          <div class="search-entry" @click.prevent="toogleList">
            <!-- <input type="search" placeholder="Recherchez une occupation"> -->
            <i class="ik ik-search"></i>
            <span> Recherchez une occupation </span>
          </div>
          <!-- <btnAdd :disabled="!canCreateoccupation"  :message="$t('data.occupation_creer_un_bail')" v-b-modal.occupationForm/> -->
          <btnAdd
            :disabled="!canCreateoccupation"
            :message="$t('data.occupation_creer_un_bail')"
            @click="bailPopup = true"
            class="create-bail"
          />
        </div>
        <!-- <SearchForm v-model="search" :placeholder="placeholderInput" style="width:25%;"/> -->
        <div class="navbar-right">
          <span v-b-modal.occupationForm>
            Vous avez <b>{{ trueOccupations.length }}</b>
            {{ $t("data.occupation")
            }}{{ trueOccupations.length > 1 ? "s" : "" }}
            {{ $t("data.occupation_au_total") }}
          </span>
          <div class="switch-occupation">
            <div class="switch-occupation-item" @click.prevent="goTo('prev')">
              <i class="ik ik-chevron-left"></i>
            </div>
            <div class="barre"></div>
            <div class="switch-occupation-item" @click.prevent="goTo('next')">
              <i class="ik ik-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 card-contain">
        <div class="separator mb-20"></div>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-alert
            variant="info"
            class="text-center"
            show
            v-if="!occupations.length"
          >
            <i class="fa fa-exclamation-triangle fa-3x"></i> <br />
            <span class="h4 d-inline-flex ml-2">{{
              $t("data.occupation_pas_de_occupation")
            }}</span>
          </b-alert>
          <div v-else>
            <occupation
              v-if="!empty(occupation)"
              :occupation="occupation"
              :total="trueOccupations.length"
              :pos="occupationPos"
              @change="selectLogement"
              @makeUpdate="setUpdateOccupation"
              @closeMobileMenuVisible="mobileMenuVisible = false"
              :moreOptionVisible="mobileMenuVisible"
            />
          </div>
        </b-overlay>
      </div>
      <!-- <OccupationList/> -->
      <div id="mySidenav" class="occupations-sidenav py-2">
        <i
          class="fa fa-2x fa-search"
          id="toogleList"
          style="position: absolute; top: 10px; right: 10px; font-size: 1rem"
          @click.prevent="toogleList"
        ></i>
        <h5 class="text-center text-black border-bottom py-2">
          {{ $t("data.occupation_occupations_disponibles") }}
        </h5>
        <form action="" class="container" onSubmit="return false">
          <b-form-input
            placeholder="Recherche une occupation..."
            v-model="search"
            ref="inputText1"
            style="margin: 15px 0; height: 50px"
          />
        </form>
        <b-container class="my-2" style="height: 76%; overflow-y: auto">
          <b-list-group>
            <b-list-group-item
              class="mb-2 p-1"
              :class="{ active: item.idOccupation == occupation.idOccupation }"
              v-for="(item, i) in items"
              :key="item.idOccupation || i"
              @click.prevent="selectLogement(item)"
            >
              <dl class="d-flex m-0">
                <dt class="pl-1 col-1">
                  <i class="fa fa-home" style="color: #191c22"></i>
                </dt>
                <dd class="col-11 pl-1 truncate" style="color: #191c22">
                  {{ item.logement.refLogement }} /
                  <span class="text-muted small">{{
                    item.logement.sousTypeLogement.libelleSousType
                  }}</span>
                </dd>
              </dl>
              <dl class="d-flex m-0">
                <dt class="pl-1 col-1">
                  <i class="fa fa-user" style="color: #191c22"></i>
                </dt>
                <dd class="col-11 pl-1 truncate" style="color: #191c22">
                  {{
                    item.locataire.nomLocataire +
                    " " +
                    item.locataire.prenomLocataire
                  }}
                </dd>
              </dl>
              <div class="text-center">
                <b-badge variant="danger" v-if="item.dateFin != null"
                  >{{ $t("data.occupation_bail_termine_le") }}
                  {{ $date(item.dateFin).format("DD MMMM YYYY") }}</b-badge
                >
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-container>
        <div
          class="m-auto w-100 d-flex justify-content-center"
          v-show="occupations.length > 5"
        >
          <paginator
            no-control
            :offset="offset"
            :total="occupations.length"
            :limit="perPage"
            :page="currentPage"
            @pageChanged="
              (page) => {
                currentPage = page;
              }
            "
            @limitChanged="
              (limit) => {
                perPage = limit;
              }
            "
          />
        </div>
      </div>
    </div>
    <!--MODAL POUR AJOUTER OU MODIFIER UNE OCCUPATION-->
    <!--<b-modal id="occupationForm" ref="occupation-form" size="xl" :title="title" hide-footer no-close-on-backdrop hide-header-close>-->
    <b-modal
      id="occupationForm"
      ref="occupation-form"
      size="xl"
      :title="title"
      hide-footer
      no-close-on-backdrop
      @close="resetOccupationFormProps"
    >
      <template #modal-title>
        <span class="ml-4 text-form-occupation">{{ title }}</span>
      </template>
      <div>
        <Occupation
          @budgetAtteint="upgradeFormule"
          @occupationAdded="addedOccupation"
          @cancelForm="cancelForm"
          @editOccupation="occupationEdited"
          :action="action"
          :provenance="provenance"
          :editOccupation="occupationToEdit"
          @createLogementSecond="goToLogement"
          @createLocataire="gotToLocataire"
          @openSignature="signatureFunc"
          @closeOccupationModal="resetOccupationFormProps"
        />
      </div>
    </b-modal>

    <div class="sign-popup" v-if="signatureModal">
      <b-overlay :show="ok" class="signature-area">
        <i class="ik ik-x sign-close" @click="signatureModal = false"></i>
        <span class="title"> Signature du contrat de bail </span>
        <p>
          A travers cette signature; les deux acteurs (le locataire et le
          bailleur) affirment être en accord vis-à-vis des termes et conditions
          mentionnés sur le dit contrat.
        </p>
        <div class="sign-area-container">
          <div class="sign-area">
            <span>Signature du Bailleur</span>
            <VueSignaturePad
              class="signature"
              width="100%"
              height="200px"
              ref="signaturePad"
              :options="optionsImg"
            />
            <div class="buttons" v-show="!ok1">
              <button @click="undo">Effacer</button>
              <button @click="save1">Enregistrer</button>
            </div>
            <span  v-show="ok1">Déjà enregistré</span>
          </div>
          <div class="sign-area">
            <span>Signature du locataire</span>
            <VueSignaturePad
              class="signature"
              width="100%"
              height="200px"
              ref="signaturePad1"
              :options="optionsImg"
            />
            <div class="buttons" v-show="!ok2">
              <button @click="undo1">Effacer</button>
              <button @click="save2">Enregistrer</button>
            </div>
            <span v-show="ok2">Déjà enregistré</span>
          </div>
        </div>
        <div class="consentir">
          <button @click="save" v-show="ok1 && ok2">Confirmer</button>
        </div>
      </b-overlay>
    </div>
    <CreateBail
      v-if="bailPopup"
      @closeBailModal="bailPopup = false"
      @occupationAdded="addedOccupation"
      @editOccupation="occupationEdited"
      :action="action"
      :provenance="provenance"
      :editOccupation="occupationToEdit"
      @createLogementSecond="goToLogement"
      @createLocataire="gotToLocataire"
      @openSignature="signatureFunc"
    />
  </div>
</template>
<script>
//import OccupationForm from "@/components/_gestion-immobiliere/OccupationForm.vue";
import OccupationForm from "@/views/gestion-immobiliere/occupations/OccupationForm.vue";

import Occupation from "./occupations/Occupation.vue";
import OccupationList from "./occupations/listOccupation.vue";
import SearchForm from "@/components/parts/SearchForm.vue";
import { mapGetters } from "vuex";
import CreateBail from "@/components/form/createBail.vue";
import AddLogementForm from "@/components/form/ajouterLogementForm.vue";
const php = require("phpjs");

export default {
  name: "list-occupations",
  components: {
    OccupationForm,
    //  DetailsOccupation,
    OccupationList,
    placeholderInput: "Rechercher une occupation",
    Occupation,
    SearchForm,
    CreateBail,
    AddLogementForm,
  },
  data: () => ({
    title: "Ouverture du contrat de bail",
    action: "edit",
    mobileMenuVisible: false,
    ok: false,
    ok1: false,
    ok2: false,
    signState: false,
    bailPopup: false,
    signLocataire: null,
    signBailleur: null,
    search: null,
    showOverlay: true,
    signatureModal: false,
    currentPage: 1,
    perPage: 10,
    occupation: {},
    occupationToEdit: null,
    occupations: [],
    trueOccupations: [],
    provenance: 1,
    optionsImg: {
      penColor: "#000",
    },
  }),
  computed: {
    ...mapGetters(["hasAccess"]),
    /**
     * Elements affichés avec prise en charge de la pagination
     */
    items() {
      return php.array_slice(this.occupations, this.offset, this.perPage);
    },
    offset() {
      return this.currentPage * this.perPage - this.perPage;
    },
    canCreateoccupation() {
      return this.hasAccess("add_occupation");
    },
    canviewoccupations() {
      return true;
    },

    /**
     * Position de l'occupation actuelle dans la liste
     */
    occupationPos() {
      return this.trueOccupations.findIndex(
        (elt) => elt.idOccupation == this.occupation.idOccupation
      );
    },
  },
  watch: {
    search(value) {
      value = value.toLowerCase();
      this.occupations = !php.empty(value)
        ? this.trueOccupations.filter((elt) => {
            return (
              elt.logement.refLogement.toLowerCase().includes(value) ||
              (elt.locataire.nomLocataire + " " + elt.locataire.prenomLocataire)
                .toLowerCase()
                .includes(value)
            );
          })
        : this.trueOccupations;
    },
  },

  beforeMount() {
    this.getOccupations();
  },
  mounted() {
    setTimeout(() => {
      $("#editLayoutItem").modal("hide");
    }, 100);

    // this.$root.$on("closeBailModal", () => {
    //     this.CreateBail = false;
    // });
  },
  methods: {
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    undo1() {
      this.$refs.signaturePad1.undoSignature();
    },
    signatureFunc() {
      this.signatureModal = true;
    },
    save() {
      this.ok = false;
      let signs = [this.signLocataire, this.signBailleur];
      if (this.signLocataire == null || this.signBailleur == null) {
        alert("Veuillez renseigner les deux signatures.");
        return;
      }
      this.$axios
        .post("/contrats/upload-signature", { fichier: signs })
        .then((response) => {
          this.$root.$emit("sign", signs);
          console.log("reponse", response.message);
          this.signatureModal = false;
          this.ok1 = this.ok2 = false;
          this.ok = true;
          signs = [];
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    save1() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
      if (!isEmpty) {
        this.signLocataire = data;
        this.ok1 = true;
      }
    },
    save2() {
      const { isEmpty, data } = this.$refs.signaturePad1.saveSignature();
      console.log(isEmpty);
      console.log(data);
      if (!isEmpty) {
        this.signBailleur = data;
        this.ok2 = true;
      }
    },
    closeSignature() {
      this.signatureModal = false;
    },
    occupationEdited(occupation) {
      this.action = "add";
      this.title = "Ouverture du contrat de bail";
      this.$bvModal.hide("occupationForm");
      this.occupation = null;
      this.selectLogement(occupation);
    },
    empty: (value) => php.empty(value),
    toogleList() {
      const btn = $("#toogleList");
      if (btn.hasClass("fa-search")) {
        btn.removeClass("fa-search").addClass("fa-times");
      } else {
        btn.addClass("fa-search").removeClass("fa-times");
      }
      $("#mySidenav").toggleClass("active");
      $("#mySidenav input:text").focus();
    },

    goTo(step) {
      const count = this.trueOccupations.length;

      let index = this.occupationPos;
      if (step == "prev") {
        index--;
      } else {
        index++;
      }
      if (index >= count) {
        index = 0;
      }
      if (index < 0) {
        index = count - 1;
      }
      this.selectLogement(this.trueOccupations[index]);
    },
    selectLogement(occupation) {
      if (php.empty(occupation)) {
        this.showOverlay = false;
        return;
      }
      this.showOverlay = true;

      const idOccupation = occupation.idOccupation || occupation;

      axios.get("occupations/" + idOccupation).then((response) => {
        this.occupation = response.result;
        let href = window.location.href.split("/"),
          last = href[href.length - 1];
        if (last == "occupations") {
          href.push(this.occupation.idOccupation);
        } else {
          href[href.length - 1] = this.occupation.idOccupation;
        }
        window.history.pushState({}, "", href.join("/"));

        this.showOverlay = false;
      });
      $("#mySidenav").removeClass("active");
      $("#toogleList").addClass("fa-search").removeClass("fa-times");
    },

    /**ecoute évènement émis;
     * fermetire formulmaire de création d'une occupation
     * redirection vers la création d'un logement
     */
    goToLogement() {
      this.$bvModal.hide("occupationForm");
      setTimeout(() => {
        this.$router.push({
          name: "logements",
          query: { formLogement: "call-logement" },
        });
      }, 50);
    },
    /**ecoute évènement émis;
     * fermetire formulmaire de création d'une occupation
     * redirection vers la création d'un locataire
     */
    gotToLocataire() {
      this.$bvModal.hide("occupationForm");
      setTimeout(() => {
        this.$router.push({
          name: "habitants",
          query: { formHabitant: "call-habitant" },
        });
      }, 50);
    },
    addedOccupation() {
      this.getOccupations();
      this.$bvModal.hide("occupationForm");
    },
    //methode de gestion appropriée du code d'erreur 430
    upgradeFormule() {
      this.$bvModal.hide("occupationForm");
      setTimeout(() => {
        this.$router.push({ name: "packs", query: { target: "upgrade-pack" } });
      }, 50);
    },
    //ecoute seweetAlert emission de la fermeture du formulaire de création d'une occupation
    cancelForm() {
      this.$bvModal.hide("occupationForm");
    },
    //recupération de la liste des logements
    getOccupations() {
      axios
        .get("occupations")
        .then((response) => response.result || [])
        .then((occupations) => {
          this.occupations = this.trueOccupations = occupations
            .sort((a, b) => {
              if (a.dateFin == null) {
                return -1;
              }
              return this.$dayjs(b.dateFin).diff(a.dateFin);
            })
            .sort((a, b) => {
              if (a.logement.refLogement < b.logement.refLogement) {
                return -1;
              }
              return 1;
            });
          let index = occupations.findIndex(
            (elt) => elt.idOccupation == this.$route.params.id
          );
          if (index == -1 && !this.$route.params.id) {
            index = 0;
          }
          this.selectLogement(occupations[index] || this.$route.params.id);
        });
    },

    removeOccupation(occupation) {
      console.log("occupation", occupation);
    },

    //edit de l'occupation
    setUpdateOccupation(occupation) {
      this.action = "edit";
      console.log("occupation", occupation);
      this.occupationToEdit = occupation;
      this.$refs["occupation-form"].show();
      this.title = "Edition du contrat de bail";
    },
    //methode pour fermer le modal de creation / edit d'une occupation
    resetOccupationFormProps() {
      this.action = "edit";
      this.title = "Ouverture du contrat de bail";
      this.$bvModal.hide("occupationForm");
      /*this.occupation = {
                loyer: null, mode: null, energie: "index", eau: "index",
                puEnergie: null, puEau: null, idLogement: null, idLocataire: null, debut: null, indexEnergie: 0,
                indexEau: 0, endLastBail: false, avance: 1, contrats: null, dureeBail: 0,
                caution: 1
         }*/
      this.occupationToEdit = null;
    },
  },
};
</script>
<style scoped>
.sign-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur( 4px );
        -webkit-backdrop-filter: blur( 4px ); */
  z-index: 10000;
}
.signature-area {
  position: relative;
  padding: 5%;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 60%;
  background: white;
  border-radius: 8px;
}
.signature-area .sign-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.4rem;
  color: #191c22;
  cursor: pointer;
}
.signature-area .title {
  font-size: 1.2rem;
  font-weight: bold;
}
.sign-area-container {
  padding: 0%;
  display: flex;
  justify-content: space-between;
  height: auto;
  width: 100%;
  background: white;
  border-radius: 8px;
}
.sign-area {
  width: 45%;
  height: 260px;
  z-index: 1000;
}
.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 10px;
}
.buttons button {
  padding: 0 10px;
  width: auto;
  height: 45px;
  border: none;
}
.signature {
  border: double 3px #191c22;
  border-radius: 5px;
  /* background-image: linear-gradient(white, white),
            radial-gradient(circle at top left, #4bc5e8, #9f6274);
        background-origin: border-box; */
  /* background-clip: content-box, border-box; */
}
.signature-area .consentir {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;
}
.consentir button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 200px;
  background: #f5365c;
  color: white;
  border-radius: 8px;
  border: none;
  font-weight: bold;
  cursor: pointer;
}
html,
body {
  overflow-x: hidden;
}
.text-form-occupation {
  font-size: 1.3em;
  color: #212121ef;
  font-weight: 800;
  text-align: center;
  margin-top: 2px;
}
.occupations-sidenav {
  height: 45em;
  border-radius: 8px;
  width: 0;
  position: absolute;
  z-index: 100;
  top: 5%;
  left: 0;
  background-color: #fff;
  overflow: hidden;
  transition: 0.5s;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
}
.occupations-sidenav.active {
  width: 24%;
}
.occupations-sidenav .list-group-item:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.8);
}
.occupations-sidenav .list-group-item.active {
  background: #f5365c;
  border-color: #f5365c;
}

.occupations-sidenav a:hover {
  color: #191c22;
}
.list-group-item.active {
  background: #f5f5f5 !important;
  border: none;
}
.list-group-item.active .text-muted {
  color: #191c22 !important;
}

.pagination .page-item.active .page-link {
  background-color: red !important;
  color: #fff;
}
.navba {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 0;
}
.navba .navbar-left {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
}
.navbar-left .search-entry {
  margin-right: 10px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  height: 50px;
  width: auto;
  background: #fefefe;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.06),
    inset 0 -2px 4px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  cursor: pointer;
}
.navbar-left .search-entry i {
  margin-right: 8px;
  color: #acacac;
  font-size: 1rem;
}
.navbar-left .search-entry span {
  color: #acacac;
  font-size: 1rem;
}
.navbar-left .search-entry input {
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  border: none;
  background: transparent;
}
.navbar-right {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  width: auto;
  height: 100%;
}
.navbar-right span {
  margin-right: 1rem;
  font-size: 0.9rem;
  color: #4d4d4d;
}
.navbar-right .switch-occupation {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  width: auto;
  height: 100%;
}
.switch-occupation .switch-occupation-item {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
  cursor: pointer;
}
.switch-occupation-item i {
  font-size: 1.8rem;
  color: #4d4d4d;
}
.switch-occupation .barre {
  margin: 0 10px;
  height: 30%;
  background: #acacac;
  width: 1px;
}

@media screen and (max-height: 450px) {
  .occupations-sidenav {
    padding-top: 15px;
  }
  .occupations-sidenav a {
    font-size: 18px;
  }
}
.modal-dialog {
  max-width: 70% !important;
}
@media screen and (max-width: 800px) {
  .navba {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .navba .navbar-left {
    margin-top: 30px;
    width: 100%;
    height: 50px;
  }
  .navbar-right {
    margin: 40px 0 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
  }
  .switch-occupation-item i {
    font-size: 1.4rem;
  }
  .occupations-sidenav {
    height: auto;
    max-height: 80vh;
    border-radius: 8px;
    width: 0;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
  }
  .occupations-sidenav.active {
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  div.card-contain {
    padding: 0;
    box-shadow: none;
  }
  .create-bail {
    display: none;
  }
  .search-entry {
    width: 100%;
  }
  .fixed-btns {
    padding: 0 15px;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 70px;
    z-index: 10000;
    /* display: none; */
  }
  .fixed-btns button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: auto;
    height: 40px;
    border-radius: 8px;
    /* box-shadow: 0 5px 10px rgba(0,0,0,0.5); */
  }
  .fixed-btns button:nth-child(1) {
    background: #191c22;
    color: white;
    font-weight: 600;
    width: calc(40% - 5px);
  }
  .fixed-btns button:nth-child(2) {
    background: #f5365c;
    color: white;
    font-weight: 600;
    width: calc(60% - 5px);
  }
  .fixed-btns button i {
    margin-left: 5px;
    font-size: 1rem;
    line-height: 0;
  }
  .menu-options {
    position: fixed;
    display: flex;
    align-items: flex-end;
    background: rgba(0, 0, 0, 0.12);
    left: 0;
    bottom: 0px;
    width: 100%;
    height: 100%;
    z-index: 10000;
  }
  .menu-options ul {
    margin: 0;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    background: white;
  }
  .menu-options ul li {
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 1px solid #eee;
  }
  .menu-options ul li i {
    margin-right: 10px;
  }
  .menu-options ul button {
    margin-top: 40px;
    height: 45px;
    border: none;
  }
  .menu-options ul li div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #454545;
  }
}
</style>
